import React from "react";
import { Button } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import routes from "../components/navigation/routes";
import LayoutCosmos from "../components/element/layout/LayoutCosmos";

const seo = {
  title: "Thanks - Oberion",
  description: null,
  canonical: routes.baseUrl + routes.thanks.to,
};
const heading = "Thanks for helping out!";
const subheading = ["Find out more about this project"];
const buttons = [
  <Button
    component={GatsbyLink}
    to={routes.home.to} variant="outlined"
    color="secondary"
    fullWidth
  >{routes.home.caption}
  </Button>,
  <Button
    component={GatsbyLink}
    to={routes.blog.to}
    variant="outlined"
    color="secondary"
    fullWidth
  >{routes.blog.caption}
  </Button>,
  <Button
    component={GatsbyLink}
    to={routes.about.to}
    variant="outlined"
    color="secondary"
    fullWidth
  >{routes.about.caption}
  </Button>,
];
const Thanks = () => (
  <LayoutCosmos seo={seo} heading={heading} subheading={subheading} buttons={buttons} />
);
export default Thanks;
